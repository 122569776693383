import React, { useEffect, useState } from 'react';
import CategoryList from '../components/category-list/CategoryList';
import ProductList from '../components/product-list/ProductList';
import ShowModal from '../components/show-modal/ShowModal';
import Header from '../components/header/Header';
import SubcategoryList from '../components/category-list/SubcategoryList';
import Navbar from '../components/navbar/Navbar';
import { useSelector, useDispatch } from 'react-redux';
import { RootStateType } from '../state/store';
import { changeUserInfoTC } from '../state/user-reducer';

const MainPage = () => {
  const userInfo = useSelector(
    (state: RootStateType) => state.userData.userInfo
  );
  const dispatch = useDispatch<any>();

  const [showCityModal, setShowCityModal] = useState(userInfo.name === null);
  const [selectedCity, setSelectedCity] = useState<number | null>(null);
  const [enteredName, setEnteredName] = useState<string>('');

  const handleCitySelection = (city: number) => {
    setSelectedCity(city);
  };

  const handleNameSubmit = () => {
    if (enteredName.trim() && selectedCity) {
      dispatch(changeUserInfoTC({ name: enteredName, city: selectedCity })); // Update user info in state
      setShowCityModal(false);
    }
  };
  
  useEffect(() => {
    document.body.style.overflow =
      !showCityModal ? 'auto' : 'hidden';
  }, [showCityModal]);

  useEffect(() => {
    if (userInfo.name === null) {
      setShowCityModal(true);
    }
  }, [userInfo]);

  return (
    <div className="page main-page">
      {showCityModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            {!selectedCity ? (
              <>
                <h2>Выберите город</h2>
                <button onClick={() => handleCitySelection(1)}>Алматы</button>
                <button onClick={() => handleCitySelection(2)}>Астана</button>
              </>
            ) : (
              <>
                <h2>Введите свое имя</h2>
                <input
                  type="text"
                  value={enteredName}
                  onChange={(e) => setEnteredName(e.target.value)}
                  placeholder="Введите имя"
                />
                <button onClick={handleNameSubmit}>Отправить</button>
              </>
            )}
          </div>
        </div>
      )}

      <div className="sticky-container">
        <Header />
        <Navbar />
      </div>
      <CategoryList />
      <SubcategoryList />
      <div className="main-container">
        <ProductList />
        <ShowModal />
      </div>
    </div>
  );
};

export default MainPage;
