import axios from 'axios';
import { Dispatch } from 'redux';
import { mainURL } from '../config';

export enum ShopEnumType {
  SET_SHOP_TYPES = 'SET_SHOP_TYPES',
  SET_SHOP_SUBTYPES = 'SET_SHOP_SUBTYPES',
  SET_SHOPS = 'SET_SHOPS',
}

export type ShopType = {
  id: number;
  name: string;
  description: string;
  order_acceptance_period: number;
  logo: string;
  background_image: string;
  basic_currency: number;
  created_at: string;
  updated_at: string;
  shops: Array<{
    id: number;
    name: string;
    address: {
      address_string: string;
      detail_info: string | null;
      lat: number;
      lng: number;
    };
    phone_numbers_to_call: string[];
    owner_id: number;
    is_open: boolean;
    is_active: boolean;
  }>;
};

export type ShopTypeType = {
  id: number;
  name: string;
  image: string;
};

export type ShopStateType = {
  shops: ShopType[];
  shopTypes: ShopTypeType[];
  shopSubtypes: ShopTypeType[];
};

export const initialState: ShopStateType = {
  shops: [],
  shopTypes: [],
  shopSubtypes: [],
};

export type SetShopTypesActionType = {
  type: ShopEnumType.SET_SHOP_TYPES;
  shopTypes: ShopTypeType[];
};

export type SetShopSubtypesActionType = {
  type: ShopEnumType.SET_SHOP_SUBTYPES;
  shopSubtypes: ShopTypeType[];
};

export type SetShopsActionType = {
  type: ShopEnumType.SET_SHOPS;
  shops: ShopType[];
};

export type ShopActionType =
  | SetShopTypesActionType
  | SetShopSubtypesActionType
  | SetShopsActionType;

export const shopsReducer = (
  state: ShopStateType = initialState,
  action: ShopActionType
) => {
  switch (action.type) {
    case ShopEnumType.SET_SHOPS:
      return { ...state, shops: action.shops };
    case ShopEnumType.SET_SHOP_TYPES:
      return { ...state, shopTypes: action.shopTypes };
    case ShopEnumType.SET_SHOP_SUBTYPES:
      return { ...state, shopSubtypes: action.shopSubtypes };
    default:
      return state;
  }
};

export const setShopsAC = (shops: ShopType[]): SetShopsActionType => {
  return { type: ShopEnumType.SET_SHOPS, shops };
};

export const setShopTypesAC = (
  shopTypes: ShopTypeType[]
): SetShopTypesActionType => {
  return { type: ShopEnumType.SET_SHOP_TYPES, shopTypes };
};

export const setShopSubtypesAC = (
  shopSubtypes: ShopTypeType[]
): SetShopSubtypesActionType => {
  return { type: ShopEnumType.SET_SHOP_SUBTYPES, shopSubtypes };
};

export const getShopsTC = () => {
  return async (dispatch: Dispatch) => {
    try {
      let shops: ShopType[] = [];
      let nextPage = `${mainURL}/en/api/v1/shops/`;

      while (nextPage) {
        const response = await axios.get(nextPage);
        shops = [...shops, ...response.data.results]; // Accumulate results
        nextPage = response.data.next; // Update the next page URL
      }

      // Fetch detailed info for each shop asynchronously
      const shopDetails = await Promise.all(
        shops.map(async (s) => {
          const detailResponse = await axios.get(`${mainURL}/en/api/v1/shops/${s.id}/`);
          return detailResponse.data; 
        })
      );


      dispatch(setShopsAC(shopDetails));
    } catch (e) {
      console.error('Failed to get shops', e);
    }
  };
};

export const getShopTypesTC = () => {
  return async (dispatch: Dispatch) => {
    try {
      let shopTypes: ShopTypeType[] = [];
      let nextPage = `${mainURL}/ru/api/v1/shops/types/`;

      while (nextPage) {
        const response = await axios.get(nextPage);
        shopTypes = [...shopTypes, ...response.data.results];
        nextPage = response.data.next;
      }

      dispatch(setShopTypesAC(shopTypes));
    } catch (e) {
      console.error('Failed to get shop types', e);
    }
  };
};

export const getShopSubtypesTC = (typeId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      let shopSubtypes: ShopTypeType[] = [];
      let page = 1;
      let nextExists = true;

      // Loop through all pages using the page counter
      while (nextExists) {
        const response = await axios.get(
          `${mainURL}/ru/api/v1/shops/subtypes/?parent_type_id=${typeId}&page=${page}`
        );

        // Ensure results exist in the response before accumulating
        if (response.data.results) {
          shopSubtypes = [...shopSubtypes, ...response.data.results];
        }

        // Check if more pages exist based on response count
        nextExists = response.data.next !== null;
        page++; // Increment page counter
      }

      dispatch(setShopSubtypesAC(shopSubtypes));
    } catch (e) {
      console.error('Failed to get shop subtypes', e);
    }
  };
};

export const getShopDetail = (shopId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(`${mainURL}/en/api/v1/shops/${shopId}/`);
      return response.data.results;
    } catch (error) {
      console.error('Failed to get shop detail:', error);
    }
  };
};
