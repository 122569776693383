import { useSelector, useDispatch } from 'react-redux';
import { getProductsTC, ProductStateType, ProductType } from '../../state/product-reducer';
import { RootStateType } from '../../state/store';
import './product-list.css';
import ProductItem from '../product-item/ProductItem';
import { CategoryType } from '../../state/category-reducer';
import { SubcategoryType } from '../../state/subcategory-reducer';
import { useEffect, useState } from 'react';
import { filterProducts } from '../../utils/fetch';

type ProductListPropsType = {};

// Компонент для отображения списка продуктов
const ProductList = (props: ProductListPropsType) => {
  const dispatch = useDispatch<any>();

  // Получаем список всех продуктов из состояния Redux
  const products = useSelector<RootStateType, ProductType[]>(
    (state) => state.products.products
  );

  // Получаем активную категорию из состояния Redux
  const activeCategory = useSelector<RootStateType, CategoryType>(
    (state) => state.categories.activeCategory
  );

  // Получаем активную подкатегорию из состояния Redux
  const activeSubcategory = useSelector<RootStateType, SubcategoryType | null>(
    (state) => state.subcategories.activeSubcategory
  );

  // Локальное состояние для отфильтрованных продуктов
  const [filteredProducts, setFilteredProducts] = useState<ProductType[]>([]);
  const [fade, setFade] = useState<boolean>(false); // Состояние для управления эффектом затухания
  const [currentPage, setCurrentPage] = useState<number>(1); // Отслеживаем текущую страницу
  const [loading, setLoading] = useState<boolean>(false); // Состояние загрузки для предотвращения повторных запросов

  // Используем хук useEffect для фильтрации продуктов при изменении активной категории, подкатегории или списка продуктов
  useEffect(() => {
    setFade(true);

    const timeout = setTimeout(() => {
      const filtered = filterProducts(products, activeCategory, activeSubcategory);
      setFilteredProducts(filtered);
      setFade(false);
    }, 300);

    return () => clearTimeout(timeout);
  }, [activeCategory, activeSubcategory, products]);

  // Хук useEffect для отслеживания события прокрутки и загрузки новых продуктов
  useEffect(() => {
    const handleScroll = () => {
      // Проверяем, достиг ли пользователь конца страницы (с небольшим запасом в 100px) и не выполняется ли загрузка
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 &&
        !loading &&
        activeCategory?.name === 'Популярное'
      ) {
        setLoading(true); // Устанавливаем состояние загрузки
        setCurrentPage((prevPage) => prevPage + 1); // Увеличиваем номер страницы
      }
    };

    // Добавляем слушатель события прокрутки
    window.addEventListener('scroll', handleScroll);
    // Убираем слушатель события прокрутки при размонтировании компонента
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, activeCategory]);

  // Хук useEffect для получения новых продуктов при изменении текущей страницы
  // useEffect(() => {
  //   const fetchProducts = async () => {
  //     if (loading && activeCategory?.name === 'Популярное') {
  //       try {
  //         await dispatch(getProductsTC(currentPage)); // Запрос продуктов с указанным номером страницы
  //       } finally {
  //         setLoading(false); // Отключаем состояние загрузки после выполнения запроса
  //       }
  //     }
  //   };

  //   // Если номер страницы больше 1 и еще есть продукты, выполняем запрос
  //   if (currentPage > 1 && activeCategory?.name === 'Популярное') {
  //     fetchProducts();
  //   }
  // }, [currentPage, activeCategory, loading]);


  // Рендеринг списка отфильтрованных продуктов
  return (
    <div className={`container`}>
      <div className="product-list">
        {filteredProducts.length === 0 ? (
          <div className="no-products"></div>
        ) : (
          filteredProducts.map((product: ProductType, index: number) => (
            <ProductItem key={`${product.id}-${index}`} product={product} />
          ))
        )}
      </div>
      {loading && <div className="loading">Загрузка продуктов...</div>}
    </div>
  );
};

export default ProductList;
